import { ClusterStatus, Visibility } from '../clusters-listing/components/clusters-list-view-table/consts';

export const k8sClustersTranslations = {
  'clusters-list-view': {
    manage: 'Manage',
    add: 'Add',
    addKubernetesApi: '__mode__ Kubernetes API Key',
    pageHeader: 'Kubernetes Clusters',
    tabs: { listing: 'Listing', 'admission-controller': 'Admission Controller' },
  },
  'clusters-list-view-table': {
    resume: 'Resume',
    pause: 'Pause',
    entity_one: 'Cluster',
    entity_other: 'Clusters',
    searchPlaceholder: 'Search Clusters',
    setStatusSuccess: 'Cluster inventory collection has been __status__ on selected clusters',
    setStatusError: 'Error: __message__',
    pageHeader: 'Kubernetes Clusters',
    'get-actions': {
      titleResume: 'Resume',
      titlePause: 'Pause',
      titleForceScan: 'Force Scan',
      titleDelete: 'Delete',
      setStatusSuccess: 'Cluster inventory collection has been __status__ on this cluster',
      setStatusError: 'Error: __message__',
      rescanSuccess: 'Resource inventory is being rescanned. Please check back soon.',
      rescanError: 'Error: __message__',
      deleteSuccess: 'selected cluster deleted successfully',
      deleteError: 'Error: __message__',
    },
    'get-columns': {
      tableTitleName: 'Name',
      tableTitleStatus: 'Status',
      tableTitleVisibility: 'Visibility',
      tableTitleClusterId: 'Cluster ID',
      tableTitleScanType: 'Scan Type',
      tableTitleCloudAccount: 'Cloud Account',
      tableTitleResources: 'Resources',
      tableTitleBadges: 'Badges',
      tableTitleDiscoveryDate: 'Discovery Date',
      tableTitleLastScanned: 'Last Scanned',
      tableTitleNextScan: 'Next Scan',
    },
    badges: {
      tooltipTitle: 'Show badges',
      title: 'Badges',
      tableTitleKey: 'Key',
      tableTitleValue: 'Value',
      tableTitleSystemGenerated: 'System Generated',
    },
    'error-modal': {
      title: 'Cluster Status',
      tableTitleMessage: 'Message',
      tableTitleRemediation: 'Remediation',
    },
    header: {
      resume: 'Resume',
      pause: 'Pause',
      itemsNumberTitle: '/ __totalCount__ Clusters (__percentage__ of total Clusters)',
      selectedItemsTitle: '__numOfSelected__ Item Selected',
    },
    'tag-status': {
      [ClusterStatus.ACTIVE]: 'OK',
      [ClusterStatus.INACTIVE]: 'Deleted',
      [ClusterStatus.PENDING]: 'In progress',
      [ClusterStatus.PAUSED]: 'Paused',
      [ClusterStatus['NOT DISCOVERED']]: 'Not discovered',
      [ClusterStatus.FAILED]: 'Failed',
      [ClusterStatus.UNAUTHORIZED]: 'Unauthorized',
    },
    'visibility-status': {
      [Visibility.FULL]: 'Full visibility',
      [Visibility.PARTLY]: 'Partial visibility',
      [Visibility.NO]: 'No visibility',
      tooltipTitleWithInfo: 'Show more info',
      tooltipTitleNoInfo: 'No info',
    },
  },
};
