const oracleAdminInstructions = `
Hello,

<br></br>
<br></br>
You are receiving this message because a member of your team needs additional details to onboard a cloud account (or accounts) to InsightCloudSec and they do not have the appropriate access to gather the information required. 
<br></br>
<br></br>
Refer to steps below on collecting the required details. If you have questions or need assistance, reach out to us through any of the options listed under <gettingSupportLink>Getting Support</gettingSupportLink> 
<br></br>
<br></br>

<b>Summary of Configuration Requirements</b>

<br></br>
<br></br>

To enable your user to onboard a cloud account for Oracle Cloud Infrastructure you will need to complete steps for three configuration items as follows:

<br></br>
<br></br>

<b>Step 1: Connecting a Tenancy (Creating a Group)</b>
<br></br>
    <tab><b>- Expected Output:</b> Creates a new Group to enable the creation of the required user</tab>

<br></br>
<br></br>

<b>Step 2: Connecting a Tenancy (Creating a User & Adding an API Key)</b>
<br></br>
    <tab><b>- Expected Output</b>: A User ID, Tenancy ID, Key Content, and Fingerprint.</tab>
    <br></br>
    <tab>- <i>Note: We recommend using a secure file sharing system to provide credentials to your non-admin user. </i></tab>

<br></br>
<br></br>

<b>Step 3: Connecting a Tenancy (Creating a Policy)</b>
<br></br>
  <tab><b>- Expected Output</b>: This action is required to ensure that the credentials you share with the non-admin user will include the appropriate access and enable them to connect your Oracle Cloud Infrastructure account(s) with InsightCloudSec successfully.</tab>

<br></br>
<br></br>

<b>External References</b>

<br></br>
<br></br>

Policy Details are as follows:
<br></br>
  <tab>- <b>Read-Only Policy</b>: contains only read permissions for the OCI resources that InsightCloudSec supports. The policy can be <readOnlyLink>obtained from our public S3 bucket</readOnlyLink> </tab>
  <br></br>
  <tab>- <b>Power User Policy</b> contains read and manage permissions for the OCI resources that InsightCloudSec supports. The policy can be <powerUserLink>obtained from our public S3 bucket</powerUserLink> </tab>
  <br></br>
  <tab><i>Note: Both the Read-Only and Power User policies will need to be updated any time InsightCloudSec supports a new OCI service.</i></tab>
  <br></br>
<docsLink>Product Documentation</docsLink>
<br></br>
<servicesLink>Supported Services for OCI</servicesLink>

<br></br>
<br></br>
<br></br>


<b>Step 1: Connect a Tenancy - Creating a Group</b>

<br></br>
<br></br>

<i>
1. Login to the <consoleLink>Oracle console</consoleLink> using the tenant you would like to connect to InsightCloudSec.
<br></br>
2. From the main navigation menu icon at the top left (hamburger menu icon), click to expand and select "Identity & Security" and then select "Domains".
<br></br>
3. Select your domain from the list.
<br></br>
4. Select "Groups" from the side navigation and then select "Create group".
<br></br>
  <tab>- Groups are required because IAM permissions are linked to groups and not individual accounts.</tab>
  <br></br>
  <br></br>
5. Give your group a name (For example: InsightCloudSec) then select "Create".
</i>

<br></br>
<br></br>
<br></br>


<b>Step 2: Connect a Tenancy - Creating a User & Adding an API Key</b>

<br></br>
<br></br>
<br></br>

<i>
1. From the main domain page in the Oracle Console, select "Users" and "Create user".
<br></br>
2. Complete the required user details as desired and ensure that the group you created earlier is selected, select "Create" when complete.
<br></br>
3. Once created, you will be redirected to the newly-created user's page. From the new user page, select "API keys" and select "Add API key".
<br></br>
  <tab>- Note: In OCI an API Key is an RSA key pair in PEM format used for signing API requests. This process generates the key pair.</tab>
<br></br>
4. Select the  "Download private key" button, and then select "Add".
<br></br>
  <tab><b>- Important Note: This is where you will download the key, make sure to save these details in a safe place to share with your non-admin user. </b></tab>
<br></br>
5. In the Configuration file preview, copy the contents and save them in a safe location. 
<br></br>
  <tab><b>- Important Note: This preview contains the User ID, Tenancy ID, and Fingerprint.</b></tab>
<br></br>
</i>

<br></br>
<br></br>
<br></br>

<b>Step 3: Connect a Tenant - Creating a Policy</b>

<br></br>
<br></br>

<i>
1. From the main menu icon at the top left select "Identity & Security" and then select "Policies".
<br></br>
2. Select the "Create Policy" button.
<br></br>
3. Complete the required policy details as desired, and ensure you've enabled "Show manual editor".
<br></br>
  <tab>- In the "Policy Builder" section, paste in either the Read-Only Policy or Power User Policy (linked above). Ensure that the group name (“InsightCloudSec”, in our provided policies) matches that of the group created in Step 1.</tab> 
  <br></br>
4. Select "Create" to submit the completed form.
</i>

<br></br>
<br></br>

Thank you for completing the required administrative setup steps! Please provide the <b>User ID</b>, <b>Tenancy ID</b>, <b>Key Content</b>, and <b>Fingerprint</b> to your non-admin user so they can complete the InsightCloudSec cloud onboarding process. 

`;

export default oracleAdminInstructions;
