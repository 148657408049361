export enum ClusterStatus {
  PAUSED = 'PAUSED',
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE',
  'NOT DISCOVERED' = 'NOT DISCOVERED',
  FAILED = 'FAILED',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export enum Visibility {
  FULL = 'FULL VISIBILITY',
  PARTLY = 'PARTIAL VISIBILITY',
  NO = 'NO VISIBILITY',
}

export const ALLOWED_TO_RESUME = [
  ClusterStatus.ACTIVE,
  ClusterStatus.FAILED,
  ClusterStatus.PAUSED,
  ClusterStatus.UNAUTHORIZED,
];

export const ALLOWED_TO_PAUSE = [ClusterStatus.ACTIVE, ClusterStatus.PAUSED, ClusterStatus.PENDING];

export enum ClusterActions {
  RESUME,
  PAUSE,
  DELETE,
  RESCAN,
}

export const MODAL_WIDTH = 800;
