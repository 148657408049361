const azureAdminInstructions = `
Hello,
<br></br>
<br></br>
You are receiving this message because a member of your team needs additional details to onboard an Azure subscription to InsightCloudSec and they do not have the appropriate level of access to gather the information required. 

<br></br>
<br></br>

Refer to the details below for steps on collecting the information required. If you have questions or need assistance, reach out to us through any of the options listed under <supportLink>Getting Support</supportLink> 

<br></br>
<br></br>

<b>Summary of Configuration Requirements</b>

<br></br>
<br></br>
To enable your user to onboard a subscription for Azure you will need to complete the following steps:

<br></br>
<br></br>

  <tab><b>- Step 1: Creating an Application Registration</b></tab>
  <br></br>
  <tab><tab>- Expected Output: A new Azure Active Directory App registration</tab></tab>
    <br></br>
  <tab><b>- Step 2: Configuring Authentication and Permissions</b></tab>
  <br></br>
  <tab><tab>- Expected Output: An API Key and Secret or Client Certificate plus Microsoft Graph API permissions granted to the App registration</tab></tab>
    <br></br>
    <tab><b>- Step 3: Creating the Role</b></tab>
  <br></br>
  <tab><tab>- Expected Output: A custom role containing the appropriate permissions to access Azure resources.</tab></tab>
    <br></br>
    <tab><b>- Step 4: Assigning the Role</b></tab>
  <br></br>
  <tab><tab>- Expected Output: This action is required to ensure that the credentials you share with the non-admin user will include the appropriate access and enable them to connect your Azure subscription(s) with InsightCloudSec successfully. </tab></tab>
    <br></br>
    <tab><tab>- <i>Note: We recommend using a secure file sharing system to provide credentials to your non-admin user.</i></tab></tab>

<br></br>
<br></br>
<br></br>

<b>External References</b>

<br></br>
<br></br>

Role details (required in Step 3) are as follows:
  <br></br>
  <tab>- Custom Reader User Role contains only read permissions for the Azure resources that InsightCloudSec supports. The policy can be obtained from our public S3 bucket. </tab>
  <br></br>
  <tab>- Reader Plus User Role contains read only permissions to all resources using regular expression wildcards (*) and a couple explicit permissions. The policy can be <customReaderLink>obtained from our public S3 bucket</customReaderLink> </tab>
  <br></br>
  <tab>- Power User Role contains read and manage permissions for the Azure resources that InsightCloudSec supports. The policy can be <readerUserRoleLink>obtained from our public S3 bucket</readerUserRoleLink> </tab>
  <br></br>
  <tab><i>- Note: Only one role is required. All user roles will need to be updated any time InsightCloudSec supports a new Azure service.</i></tab>
<br></br>
<br></br>
<productDocLink>Product Documentation is available here.</productDocLink>

<br></br><br></br>

<b>Step 1: Creating an Application Registration</b>

<br></br>
<br></br>

<ol>
<li>Login to the Azure Portal and navigate to “Azure Active Directory → App Registrations”.</li>
<li>Click “New registration”.</li>
<li>Provide the required information for the registration and click “Register”.</li>
  <tab>- Note: We recommend using the Single Tenant option.</tab>
<li>Copy the Application ID and the Tenant ID. Save these for later.</li>
</ol>

<br></br>
<br></br>

<b>Step 2: Configuring Authentication and Permissions</b>

<br></br>
<br></br>

<ol>
<li>From the new application registration’s overview page, navigate to “Certificates & secrets”.</li>
<li>Generate and upload a certificate or create a new client secret. </li>
    <tab>- If using a certificate, copy the PEM certificate and Certificate Thumbprint. <b>Save these for later.</b></tab>
    <br></br>
    <tab>- If using an API Key and Secret, copy the Secret Key value. <b>Save this for later.</b></tab>
    <li>In the left side menu, click “API permissions”.</li>
    <li>Click “Add a permission → Microsoft Graph”.</li>
    <li>Click “Application Permissions”, then search for and add the following permissions:</li>
    <tab>- <code>Directory.Read.All</code></tab>
    <br></br>
    <tab>- <code>AuditLog.Read.All</code></tab>
    <br></br>
    <li>Click “Grant admin consent for Default Directory”, then confirm the selection.</li>
</ol>

<br></br>
<br></br>

<b>Step 3: Creating the Custom Role</b>

<br></br>
<br></br>

<ol>
  <li>Navigate to “Subscriptions” and select the Subscription you wish to onboard</li>
  <li>On the “Overview” page, copy the Subscription ID. <b>Save this for later.</b></li>
  <li>Navigate to “Access control (IAM)”, then click “Add → Add custom role”.</li>
  <li>Provide the required information for the role and select “Start from scratch”.</li>
  <li>Click the “JSON” tab, then click “Edit”.</li>
  <li>Using one of the roles listed above, copy and paste the JSON into the field, ensuring you update the placeholder Subscription ID with the ID you copied earlier.</li>
  <li>Click “Save” then click “Review + create” then “Create”.</li>
</ol>

<br></br>
<br></br>

<b>Step 4: Assigning the Custom Role</b>

<br></br>
<br></br>

<ol>
  <li>Return to “Access control (IAM)”, then click “Add → Add role assignment”.</li>
  <li>Search for the custom role you just created and select it, then click “Next”.</li>
  <li>Click “+ Select members”, then search for and select the new App registration from earlier.</li>
  <li>Click “Save”.</li>
<ol>

<br></br>
<br></br>
Thank you for completing the required administrative setup steps! Please provide the following values to your non-admin user so they can complete the InsightCloudSec cloud onboarding process:
<b>
<br></br>
<tab>- Application ID</tab>
<br></br>
<tab>- Tenant ID</tab>
<br></br>
<tab>- Client Secret Key / Certificate and Private Key (PEM file) and Certificate Thumbprint</tab>
<br></br>
<tab>- Subscription ID</tab>
<br></br>
</b>

`;

export default azureAdminInstructions;
