const awsNonAdminInstructions = `
Hello,
<br></br>
<br></br>
You are receiving this message because a member of your team needs additional details to onboard an AWS account or organization to InsightCloudSec and they do not have the appropriate level of access to gather the information required. 

<br></br>
<br></br>

Refer to the details below for steps on collecting the information required. If you have questions or need assistance, reach out to us through any of the options listed under <supportLink>Getting Support</supportLink> 

<br></br>
<br></br>

<b>Summary of Configuration Requirements</b>

<br></br>
<br></br>

To enable your user to onboard a cloud account or organization for AWS you will need to complete the following steps:

<br></br>
<br></br>

<ol>
  <li><b>Step 1: Establishing an Authenticating Principal</b></li>
    <tab>- Expected output: Expected output: An Authenticating Principal ID or an IAM User (if using AWS GovCloud or China)</tab>
  <li><b>Step 2: Deploying IAM Role(s)</b></li>
    <tab>- Expected Output: One or more AWS Account(s) with an InsightCloudSec IAM Role.</tab>
  <li><b>Step 3: Sharing Credentials</b></li>
    <tab>- Expected Output: The non-admin user will be able to connect your AWS account(s) or Organization with InsightCloudSec successfully. </tab>
    <br></br>
    <tab>- <i>Note: We recommend using a secure file sharing system to provide credentials to your non-admin user. </i></tab>
</ol>

<br></br>
<br></br>
<br></br>

<b>External References</b>

<br></br>
<br></br>

- This CFT contains all the parameters, policies, and roles necessary for onboarding an AWS account with InsightCloudSec: <iamCftLink>Rapid7 AWS IAM Roles CFT</iamCftLink>
<br></br>
- <productDocsLink>Product Documentation is available here</productDocsLink>


<br></br>
<br></br>

<b>Step 1: Establish an Authenticating Principal</b>
<br></br>
<br></br>

If using AWS Commercial:

<br></br>
<br></br>

1. The Authenticating Principal ID and External ID included with these instructions are required in Step 2.

<br></br>
<br></br>
If using AWS GovCloud or China:
<br></br>

<ol>
  <li>Login to the AWS Console for the account you are onboarding for InsightCloudSec.</li>
  <li>Navigate to “IAM → Users” and create a user with the name: “rapid7/rapid7-authenticating-principal”</li>
  <li>Generate an access key for the user and copy the Access Key ID and Access Key Secret. Save these for later.</li>
</ol>

<br></br>
<br></br>

<b>Step 2: Deploy IAM Role(s)</b>

<br></br>
<br></br>

Login to the AWS Console and decide which account(s) to onboard.

<br></br>
<br></br>

If the account is part of an organization:

<br></br>
<br></br>

<ol>
  <li>Login to the AWS Console for the Management Account and navigate to “CloudFormation -> Stacks”.</li>
  <li>Click “Create Stack -> With new resources (standard)”.</li>
  <li>Provide the following Amazon S3 URL and then click “Next”: <cftLink>Management Account CFT Link</cftLink></li>
  <li>Provide the CFT with the required information and execute it.</li>
  <li>Navigate to “CloudFormation -> StackSets”.</li>
  <li>Click “Create StackSet”.</li>
  <li>Provide the following Amazon S3 URL and then click “Next”: <cftLink>Member Account CFT Link</cftLink></li>
  <li>Provide the CFT with the required information and execute it.</li>
</ol>

<br></br>

If the account(s) are individual:

<br></br>
<br></br>

<ol>
  <li>Login to the AWS Console for the individual account and navigate to “CloudFormation -> Stacks”.</li>
  <li>Click “Create Stack -> With new resources (standard)”.</li>
  <li>Provide the following Amazon S3 URL and then click “Next”: <cftLink>Member Account CFT Link</cftLink></li>
  <li>Provide the CFT with the required information and execute it.</li>
  <li>Repeat previous steps 1-4 for each account.</li>
</ol>

<br></br>
<br></br>

<b>Step 3: Share Credentials</b>

<br></br>
<br></br>

<ol>
  <li>Using a secure file sharing service, share the aforementioned credentials with the non-admin user attempting to perform Cloud Setup in InsightCloudSec:
    <ul>
      <li>Role ARN</li>
      <li>External ID</li>
      <li>(Optional: GovCloud/China) Access Key ID</li>
      <li>(Optional: GovCloud/China) Access Key Secret</li>
    </ul>
  </li>
</ol>

<br></br>
<br></br>

Thank you for completing the required administrative setup steps! 
`;

export default awsNonAdminInstructions;
