import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export enum Language {
  EN = 'en',
}

export enum Locale {
  EN_US = 'en-US',
}

export const I18NEXT_NAMESPACE_R7UI = 'rapid7UI';
export const I18NEXT_NAMESPACE_DEFAULT = 'ics';
export const I18NEXT_DEFAULT_LANG = Language.EN;

export const bundles = Object.values(Language).reduce((acc, lang) => {
  acc[lang] = {
    /* eslint-disable @typescript-eslint/no-var-requires */
    [I18NEXT_NAMESPACE_DEFAULT]: require(`./locales/${lang}/${I18NEXT_NAMESPACE_DEFAULT}/index.ts`).default,
    [I18NEXT_NAMESPACE_R7UI]: require(`./locales/${lang}/rapid7-ui/index.ts`).default,
    /* eslint-enable @typescript-eslint/no-var-requires */
  };

  return acc;
}, {} as Record<Language, any>);

export const initialize = () =>
  i18n.use(initReactI18next).init({
    defaultNS: I18NEXT_NAMESPACE_DEFAULT,
    fallbackLng: Language.EN,
    interpolation: {
      escapeValue: false,
      prefix: '__',
      suffix: '__',
    },
    resources: bundles,
  });
