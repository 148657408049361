export const systemEvents = {
  'page-header': 'System Events',
  table: {
    columns: {
      resource: 'Resource',
      type: 'Type',
      source: 'Source',
      scope: 'Scope',
      detected: 'Last Detected',
      description: 'Description',
      'resource-type': 'Resource Type',
      'not-applicable': 'N/A',
    },
    actions: {
      hide: 'Hide Events',
      restore: 'Restore Events',
    },
    'action-messages': {
      success: {
        hide: 'Successfully hid System Event(s)',
        unhid: 'Successfully unhid System Event(s)',
      },
    },
  },
  'resource-types': {
    organization: 'Cloud Organization',
  },
};

export default systemEvents;
