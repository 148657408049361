import resourcePanel from './components/resource-panel';

export const components = {
  'advanced-filter-next': {
    components: {
      'advanced-filter-next-create-filter-modal': {
        title: 'Create New Saved Filter',
        success: 'Successfully created Saved Filter: __name__',
      },
      'advanced-filter-next-saved-filters-drawer': {
        title: 'Saved Filters',
        desc: 'Listed below are all Saved Filters for the currently selected feature. (Note: Saved filters for other capabilities are accessible on their feature pages.)',
        'table-search-placeholder': 'Search',
        'expanded-row-title': 'Description',
        'default-view-chip': 'Default Filter',
        'apply-filter-button': 'Apply Filter',
        'edit-filter-button': 'Edit Configuration',
        'edit-filter-button-disabled-message': 'Only an Admin can edit a public filter',
        'delete-filter-button': 'Delete Filter',
        'delete-filter-button-disabled-message': 'Only an Admin can edit a public filter',
        'delete-filter-warning': 'You attempted to Delete this filter. Please confirm this action.',
        'table-column-headers': {
          name: 'Name',
          access: 'Access',
          author: 'Author',
          dateCreated: 'Date Created',
        },
      },
      'advanced-filter-condition': {
        selectPropertyPlaceholder: 'Select a property',
        requiredTypeMessage: 'You must select a filter type.',
        requiredOperatorMessage: 'You must select an operator.',
      },
      'advanced-filter-chips': {
        clear: 'Clear All',
        true: 'True',
        false: 'False',
        save: 'Save Filter',
        edit: 'Edit Filter',
        'delete-filter-button-disabled-message': 'Only an Admin can edit a public filter',
        'saved-management': 'View Saved Filters',
      },
      'advanced-filter-next-drawer': {
        description: 'Select and apply advanced filters to narrow your view.',
        apply: 'Apply',
        cancel: 'Cancel',
        clear: 'Clear All Filters',
        add: 'Add Condition',
        title: 'Advanced Filtering',
      },
      'advanced-filter-next-edit-saved-filter-drawer': {
        drawerTitle: 'Edit Filter',
        title: 'Details',
        desc: 'Edit Name and/or Description of Saved Filter.',
        buttons: {
          save: 'Save',
          cancel: 'Cancel',
          popConfirmTitle: 'This filter has been modified, to confirm changes click “Save” otherwise click “Cancel”.',
        },
      },
      'advanced-filter-next-save-form': {
        form: {
          nameValidation: 'Filter name required.',
          nameLengthValidation: 'Filter name must be less than 128 characters',
          nameLabel: 'Filter Name',
          descLabel: 'Description',
          defaultLabel: 'Set as Default Filter',
          defaultTooltip:
            'Selecting “Set as Default Filter” saves this filtered view as your default on the currently selected feature',
          globalLabel: 'Make this a Public Filter',
          globalTooltip:
            'Selecting “Make this a Public Filter” makes this filter visible for all users in your organization to enable.',
        },
      },
    },
    viewSaved: 'View Saved Filters',
    label: 'Filters:',
    add: 'Add Filter',
    requiredValueMessage: 'You must select a value.',
  },
  tables: {
    'applications-table': {
      search: 'Search Applications',
      entity_one: 'Application',
      entity_other: 'Applications',
      application: 'Application',
      description: 'Description',
      accounts: 'Cloud Accounts',
      poc: 'Point of Contact',
      category: 'Category',
      businessCritical: 'Business Critical',
      favorited: 'Favorited',
      cloudTypes: 'Cloud Types',
      resourceCount: 'Resource Count',
      cores: 'Instance Cores',
      blockStorage: 'Block Storage',
      objectStorage: 'Object Storage',
      resourceDetails: 'Resource Details',
      settings: 'Edit Settings',
      viewResources: 'View Resources',
      star: 'Favorite',
    },
    'resource-group-table': {
      entity_one: 'Resource Group',
      entity_other: 'Resource Groups',
    },
    'edh-consumers-table': {
      entity_one: 'Configuration',
      entity_other: 'Configurations',
    },
  },
  'resource-scope': {
    scopes: 'Scopes',
    applications: 'Applications',
    resourceGroups: 'Resource Groups',
    clouds: 'Clouds',
    cloudsDisabled: 'Cloud filtering is not supported with active application scoping',
    groupsDisabled: 'Resource Group filtering is not supported with active cloud/application scoping',
    applicationsDisabled: 'Application filtering is not supported with active cloud/resource group scoping',
    components: {
      'resource-scope-clouds-table': {
        badgesLabel: 'Must Have all Badges',
        entity_one: 'Cloud',
        entity_other: 'Clouds',
        placeholder: 'Search Clouds',
        name: 'Name',
        selectBadges: 'Select Badges',
        cloud: 'Cloud',
        accountId: 'Account ID',
      },
    },
  },
  shared: {
    'feature-flag-guard': {
      error: 'The feature __feature__ is not enabled',
    },
    errors: {
      'server-unreachable': {
        title: 'Server Unreachable',
        message: 'The server is currently unreachable. Please try again later.',
      },
    },
    settings: 'Settings',
  },
  'search-params-filter-form': {
    filters: 'Filters',
    reset: 'Reset',
  },
  'query-filter-panel': {
    components: {
      'qfp-filter-apply-form': {
        description:
          'This filter accepts or requires configuration input. You can use options below to provide that input and click APPLY to see filter results. If APPLY is disabled, then required inputs are missing.',
        warning:
          'The selected collection has no data and will likely cause issues with this filter. Please select another collection and try again.',
        value_one: '__count__ Value',
        value_other: '__count__ Values',
        storedData: 'Stored Data',
        title: 'Customize Resource Query Filter',
        selectCollection: 'Select Collection',
        create: 'Create',
        clear: 'Clear',
        apply: 'Apply',
        collectionName: 'Collection Name',
        collectionNamePlaceholder: 'New collection name',
        collectionItems: 'Enter values',
        items: 'Items (__count__)',
        newCollectionDescription: 'Create a new collection',
        existingCollectionDescription: 'Select an existing set of collections',
        requiredMessage: '__field__ is required',
      },
      'qfp-table-row-apply-btn': {
        apply: 'Apply',
        applied: 'Applied!',
      },
    },
  },
  'advanced-filter': {
    title: 'Filters:',
    components: {
      'af-filter-control-chips': {
        true: 'True',
        false: 'False',
      },
      'edit-saved-filters': {
        buttons: {
          save: 'Save',
          cancel: 'Cancel',
          popConfirmTitle: 'This filter has been modified, to confirm changes click “Save” otherwise click “Cancel”.',
        },
        title: 'Details',
        desc: 'Edit Name and/or Description of Saved Filter.',
        form: {
          nameValidation: 'Filter name required.',
          nameLengthValidation: 'Filter name must be less than 128 characters',
          nameLabel: 'Filter Name',
          descLabel: 'Description',
          defaultLabel: 'Set as Default Filter',
          defaultTooltip:
            'Selecting “Set as Default Filter” saves this filtered view as your default on the currently selected feature',
          globalLabel: 'Make this a Public Filter',
          globalTooltip:
            'Selecting “Make this a Public Filter” makes this filter visible for all users in your organization to enable.',
        },
      },
      'saved-filters': {
        title: 'Saved Filters',
        desc: 'Listed below are all Saved Filters for the currently selected feature. (Note: Saved filters for other capabilities are accessible on their feature pages.)',
        'table-search-placeholder': 'Search',
        'expanded-row-title': 'Description',
        'default-view-chip': 'Default Filter',
        'apply-filter-button': 'Apply Filter',
        'edit-filter-button': 'Edit Configuration',
        'edit-filter-button-disabled-message': 'Only an Admin can edit a public filter',
        'delete-filter-button': 'Delete Filter',
        'delete-filter-button-disabled-message': 'Only an Admin can edit a public filter',
        'delete-filter-warning': 'You attempted to Delete this filter. Please confirm this action.',
        'table-column-headers': {
          name: 'Name',
          access: 'Access',
          author: 'Author',
          dateCreated: 'Date Created',
        },
      },
      control: {
        add: 'Add Filter',
        save: 'Save Filter',
        edit: 'Edit Filter',
        'saved-management': 'View Saved Filters',
        clear: 'Clear All',
        active: '__name__',
      },
      'new-filter': {
        title: 'Create New Saved Filter',
        form: {
          nameValidation: 'Filter name required.',
          nameLengthValidation: 'Filter name must be less than 128 characters',
          nameLabel: 'Filter Name',
          descLabel: 'Description',
          descValidation: 'Description required',
          defaultLabel: 'Set as Default Filter',
          defaultTooltip:
            'Selecting “Set as Default Filter” saves this filtered view as your default on the currently selected feature.',
          globalLabel: 'Make this a Public Filter',
          globalTooltip: 'Selecting "Make this a Public Filter" enables this filter for all of your organizations.',
        },
      },
    },
  },
  'default-empty-data-message': {
    message: 'No data to display - Update or clear the filters',
    detail: 'For more info refer to <a>docs</a>',
  },
  'entity-drawer': {
    defaultDownloadLabel: 'Download JSON',
  },
  'resource-panel': {
    components: {
      'public-accessible-clickable-content': {
        public: 'Public',
        notPublic: 'Not Public',
        publicCount: 'Public (__public__)',
      },
      'resource-panel-submanagement-table': {
        deleteResource: 'Delete Resource',
        showResource: 'Show Resource',
        search: 'Search',
      },
      'resource-panel-action-modal': {
        confirm: 'Confirm that you want to execute this action',
        title: 'Execute Action',
        actions: '(__count__) Actions',
        success: 'Successfully executed the action',
        submissionError: 'There was an error executing the action',
      },
      'resource-panel-header': {
        cloudAccount: 'Cloud Account',
        providerId: 'Provider ID',
        resourceId: 'Resource ID',
        directLink: 'Direct Link',
        dateDiscovered: 'Date Discovered',
        region: 'Region',
        latestHarvest: 'Latest Harvest',
        azureId: 'ID (Path)',
        gceId: 'ID (Unique ID)',
        awsId: 'ID (ARN)',
        id: 'ID',
      },
      'resource-panel-activity-scheduled-events-table': {
        components: {
          'next-scheduled-run-cell': {
            inProgress: 'Event in progress',
            notInProgress: 'Event is not scheduled',
          },
          'event-state-cell': {
            eventInactive: 'Inactive',
            eventActive: 'Active',
            eventDeletedTooltip: 'The event has been deleted and is no longer available for inspection.',
            resourceDeletedTooltip: 'This resource has been deleted so some of its properties cannot be displayed.',
            resourceExemptedTooltip: 'This resource has been exempted and is no longer actionable.',
          },
          'last-run-status-cell': {
            lastRunStatusSuccessTooltip: 'Last event execution was successful',
            lastRunStatusErrorTooltip: 'Last event execution was not successful',
            lastRunStatusTooltip: 'Last event execution resulted in __status__',
            lastRunStatusNotRunTooltip: 'This event has not yet run. Check back later!',
          },
        },
        status: 'Status',
        mutationSuccess: 'Scheduler notified of changes. Refresh view after a few moments.',
        okText: 'Confirm',
        modalTitle: 'Confirm Action',
        modalContent: 'Please confirm that you want to perform this action',
        mutationError: 'An error occured performing this action',
        eventState: 'State',
        lastRun: 'Last Run',
        entity_one: 'Scheduled Event',
        entity_other: 'Scheduled Events',
        eventType: 'Event Type',
        account: 'Account',
        name: 'Name',
        cloud: 'Cloud',
        schedule: 'Schedule',
        resourceType: 'Resource Type',
        description: 'Description',
        scheduledBy: 'Scheduled By',
        creationTime: 'Creation Time',
        bot: 'Bot',
        resume: 'Resume',
        retry: 'Retry',
        remove: 'Remove',
        suspend: 'Suspend',
      },
      'resource-panel-activity-event-history-table': {
        timestamp: 'Date',
        user: 'User',
        action: 'Action',
        sourceIp: 'Source IP',
        entity_one: 'History Event',
        entity_other: 'History Events',
        codeViewTitle: 'Code View',
        launch: 'Launch',
      },
    },
    'submanagement-table-tab-name': '(__count__) __label__',
    'ssm-document-tab': 'Content',
    'iam-policies-tab': 'IAM Policies',
    'iam-policy-tab': 'IAM Policy',
    'inline-policies-tab': 'Inline Policies',
    'error-empty-description': 'The resource with id __resourceId__ does not exist.',
    'download-prompt': 'Download JSON',
    tabs: {
      ...resourcePanel,
      tags: {
        description:
          'Use the form below to add new tag values. You can edit the key/value pairs for existing tags by clicking on the table cell. When you are finished click save at the base of the table. This will schedule the change. You can monitor the status of the change via the Activity tab.',
        tags: '(__count__) Tags',
        key: 'Key',
        value: 'Value',
        submit: 'Submit',
        entity_one: 'Tag',
        entity_other: 'Tags',
        save: 'Save Tags',
        reset: 'Reset Tags',
        success: 'Tag change successfully queued. Use the activity feed to monitor status of the change',
      },
      activity: {
        activity: '(__count__) Activity',
        scheduledEvents: '(__count__) Scheduled Events',
        cloudHistory: '(__count__) Cloud History',
      },
      actions: {
        actions: '(__count__) Actions',
      },
      properties: {
        title: 'Properties',
        'empty-state-message': 'Properties are currently not available for',
      },
      'public-access': {
        title: 'Public Access',
        'title-not-accessible': 'No checks indicating Public Access for this resource.',
        titleExtra: 'Publicly Accessible',
        columnTitles: {
          source: 'Source',
          description: 'Description',
          identifiedOn: 'Identified On',
          result: 'Result',
        },
        public: 'Public',
        notPublic: 'Not Public',
        search: 'Search Public Access',
        header: {
          accessible: 'Resource is Publicly Accessible',
          inaccessible: 'Resource is not Publicly Accessible',
        },
        source: {
          insights: 'Insight (__source__)',
          'harvest-eval': 'Harvested Insights ICS',
        },
      },
      'insight-findings': {
        header: 'Insight Findings',
        columnTitles: {
          author: 'Author',
          insight: 'Insight',
          severity: 'Severity',
          description: 'Description',
          identifiedOn: 'Identified On',
          packs: 'Packs',
        },
        noName: 'No Name',
        'empty-state-message': 'No results',
      },
      vulnerabilities: {
        title: 'Vulnerabilities',
        tooltip: 'There are no Vulnerabilities for this resource',
        source: {
          ivm: '(__count__) InsightVM',
          inspector: '(__count__) AWS Inspector',
          vm: '(__count__) Vulnerability Management',
        },
      },
      'threat-findings': {
        title: 'Threat Findings',
        tooltip: 'There are no Threat Findings for this resource',
      },
      'source-documents': {
        title: 'Source Documents',
        tooltip: 'There are no Source Documents for this resource',
        'empty-state-message': 'Source document properties are currently not available for',
      },
      dependencies: {
        title: 'Related Resources',
        tooltip: 'There are no related resources for this resource',
        visualize: 'Visualize Related Resources',
      },
      'attack-paths': {
        title: '(__count__) Attack Paths',
        tooltip: 'There are no attack paths for this resource',
        severity: 'Severity',
        apName: 'Attack Path Name',
        targetResource: 'Target Resource',
        origin: 'Origin',
        age: 'Age',
      },
    },
  },
  'session-timeout-modal': {
    continue: 'Continue Session',
    cancel: 'Logout',
    title: 'Session Expiring',
    warning: 'Your session will expire and you will be logged out in: __duration__',
  },
  table: {
    selected_one: '__count__ Item Selected',
    selected_other: '__count__ Items Selected',
    clearSelection: 'Clear Selection',
    action: 'Action',
    download: 'Download',
    components: {
      'table-header-count': {
        loading: '<spinner /> __entityName__',
        loaded: '<highlighted>__count__</highlighted> __entityName__',
        loadedUnfiltered: '<highlighted>__count__</highlighted>/ __total__ __entityName__ (__countPercentage__%)',
        loadedUnfilteredLessThanOnePercent:
          '<highlighted>__count__</highlighted>/ __total__ __entityName__ (less than 1%)',
        loadedUnfilteredBetween99and100Percent:
          '<highlighted>__count__</highlighted>/ __total__ __entityName__ (greater than 99%)',
      },
      'table-header-column-options-dropdown': {
        columnOptions: 'Column Options',
        selected: 'Selected',
        apply: 'Apply',
      },
      'table-action-popconfirm-warning': {
        title: 'Warning: You cannot undo this action.',
        'cancel-btn': 'Cancel',
      },
    },
  },
  visualization: {
    'visualization-collapsible-panel': {
      show: 'Show',
      hide: 'Hide',
    },
  },
  intlNumber: '__val, number__',
  documentationLink: 'Documentation',
  contactUsModal: {
    title: 'We are here to help',
    okText: 'Send',
    api: {
      successMessage: 'Message received! Rest assured a support team member will get back to you as soon as possible',
      errorMessage: 'Unable to open a support request at this time',
    },
    formField: {
      name: { label: 'Name', error: 'Name is required' },
      email: { label: 'Email', error: 'Email is required' },
      subject: { label: 'Subject', error: 'Subject is required' },
      description: { label: 'How can we help you?', error: 'Description is required' },
      ticketType: { label: 'ICS Ticket Type', error: 'Ticket Type is required' },
      ticketTypeLabel: {
        bug: 'Bug',
        featureRequest: 'Feature Request',
        general: 'General',
      },
    },
  },
  'attack-path-analysis': {
    relatedResources: 'Related Resources',
    publicInternet: 'Public Internet',
    publicAccess: 'Public Access',
    public: 'Public',
    vulnerabilities: 'Vulnerabilities',
    vulnerability: 'Vulnerability',
    insight: 'Insight',
    insightFindings: 'Insight Findings',
    threatFindings: 'Threat Findings',
    accountName: 'Account Name',
    cloudProvider: 'Cloud Provider',
    dateDiscovered: 'Date Discovered',
    seeAllDetails: 'See All Details',
    resourceDetails: 'Resource Details',
    resourceType: 'Resource Type',
    id: 'ID',
    region: 'Region',
    resourceTypeId: 'Resource Type ID',
    providerId: 'Provider ID',
    cloudeType: 'Cloud Type',
    td: 'Top-down',
    lr: 'Left-right',
    orientation: 'Orientation',
    securityView: 'Security View',
    showInfoPanel: 'Show Info Panel',
    expandGroup: 'Expand Group',
    directLink: 'Direct Link',
    latestHarvest: 'Latest Harvest',
    justification: 'Justification',
    showRelatedResources: 'Show Related Resources',
    quickLook: 'Quick Look',
    makeRootNode: 'Make Root Node',
    print: 'Print',
    noRelated: 'No Related Resources',
    noRelatedDesc: 'The selected resource __resourceId__ contains no related resources.',
    relatedResourcesInstructions:
      'This page shows related resources. To explore the graph further, double-click on a node. For more context options, right-click on a node.',
  },
  profile: {
    theme: {
      light: 'Light',
      dark: 'Dark',
      auto: 'Auto',
    },
    'date-created': 'Date Created',
    'expiration-date': 'Expiration Date',
    active: 'Active',
    username: 'Username',
    name: 'Name',
    email: 'Email Address',
    password: 'Password',
    'active-theme': 'Active Theme',
    'system-health': {
      property: 'System Health Notifications',
      description:
        'This can reveal important system checks that can help prevent and troubleshoot unexpected system failures.',
      error: 'You do not have permission to adjust this setting',
    },
    version: 'Version: __version__',
    'api-keys': {
      description:
        'API keys can be used to programmatically access InsightCloudSec with long term tokens. Note that to generate these tokens you must be an administrator or have the API Key permission granted to you by an administrator.',
      error: 'You do not have permission to adjust this setting',
      generate: 'Generate',
      modal: {
        title: 'Personal API Key',
        alert: {
          title: 'Warning',
          message:
            'Copy and save the key below in a secure location. This is your only opportunity to save this key; if you lose this information you will have to generate a new key.',
          description:
            'Copy and save the key below in a secure location. This is your only opportunity to save this key, if you lose this information you will have to generate a new key.',
        },
      },
    },
  },
  sideNav: {
    module: {
      'app.dashboard': { label: 'Summary' },
      'app.profile': { label: 'Profile' },
      'app.clouds': { label: 'Cloud Accounts' },
      'app.clusters': { label: 'Kubernetes Clusters' },
      'app.collections': { label: 'Data Collections' },
      'app.resources': { label: 'Resources' },
      'app.groups': { label: 'Resource Groups' },
      'app.applications': { label: 'Applications' },
      'app.tags': { label: 'Tag Explorer' },
      'app.usc': { label: 'Layered Context' },
      'app.identity_analysis': { label: 'Identity Analysis' },
      'app.apa': { label: 'Attack Paths' },
      'app.threat_findings': { label: 'Threat Findings' },
      'app.compliance': { label: 'Compliance Scorecard' },
      'app.vulnerability_assessment': { label: 'Container Vulnerabilities' },
      'app.hva': { label: 'Vulnerabilities' },
      'app.just-in-time-iam': { label: 'Just In Time IAM' },
      'app.iac': { label: 'Infrastructure as Code' },
      'app.iam': { label: 'Access Explorer' },
      'app.insights': { label: 'Insights' },
      'app.filters': { label: 'Query Filters' },
      'app.exemptions': { label: 'Exemptions' },
      'app.botfactory': { label: 'BotFactory' },
      'app.events': { label: 'Scheduled Events' },
      'app.integrations': { label: 'Integrations' },
      'app.plugins': { label: 'Plugins' },
      'app.identity': { label: 'Identity Management' },
      'app.lpa': { label: 'IAM Settings' },
      'app.harvesting': { label: 'Harvesting Strategies' },
      'app.settings': { label: 'System Administration' },
    },
  },
  'lazy-select': {
    placeholder_one: 'Select Item...',
    placeholder_other: 'Select Items...',
    'placeholder-none': 'No available items',
    'tag-placeholder': '__count1__ / __count2__ selected',
    'select-all': 'Select all',
    'deselect-all': 'Deselect all',
    'search-tooltip': 'Use search to narrow results and enable select all.',
    impl: {
      'harvest-status': {
        options: {
          active: 'Active',
          'invalid-creds': 'Invalid Credentials',
          paused: 'Paused',
          'assume-role': 'Assume Role Failure',
          suspended: 'Suspended',
        },
      },
      'harvest-role': {
        options: {
          consumer: 'Consumer',
          producer: 'Producer',
          'cloudtrail-consumer': 'CloudTrail Consumer',
          'cloudtrail-producer': 'CloudTrail Producer',
          'not-configured': 'Not Configured',
        },
      },
    },
  },
  'filters-panel': {
    'apply-btn': 'Apply',
    'clear-all-btn': 'Clear All',
  },
  bot: {
    'bot-state-labels': {
      running: 'Running',
      paused: 'Paused',
      scanning: 'Scanning',
    },
    'bot-state-tooltips': {
      RUNNING: 'This Bot is active and currently in-use',
      PAUSED: 'This Bot is currently not active',
      SCANNING: 'This Bot is currently processing data',
    },
  },
};
