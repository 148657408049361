const layeredContext = {
  'lc-list-view-by-cloud-table': {
    'column-titles': {
      vulnerabilities: 'Vulnerabilities',
      resources: 'Resources',
      insights: 'Insights',
      resourceName: 'Cloud Name',
      publicAccess: 'Public Access',
      publicAccessTooltip: 'Indicates if the resource has public access exposure. Click "Public" for specific details.',
    },
    tooltips: {
      public_access_tooltip:
        'Indicates if the resource has public access exposure. Click "Public" for specific details.',
    },
    entity_one: 'Cloud Account',
    entity_other: 'Cloud Accounts',
    search: {
      placeholderLabel: 'Search Cloud Accounts',
    },
    'row-action': {
      viewDetailsLabel: 'View Resources',
      filterGroup: 'Add Filters & Scopes',
      scopeByAccount: 'Scope by Account',
      scopeByPublicAccess: 'Scope by Public Access',
      addCriticalResources: 'Add Critical Resources to Filters',
    },
    empty_message: 'No data to display - Update or clear the filters',
  },
  'lc-list-view-by-application-table': {
    'column-titles': {
      businessCritical: 'Business Critical',
      vulnerabilities: 'Vulnerabilities',
      resources: 'Resources',
      insights: 'Insights',
      applicationName: 'Application Name',
      poc: 'Point of Contact',
      publicAccess: 'Public Access',
      publicAccessTooltip: 'Indicates if the resource has public access exposure. Click "Public" for specific details.',
    },
    tooltips: {
      public_access_tooltip:
        'Indicates if the resource has public access exposure. Click "Public" for specific details.',
    },
    entity_one: 'Application',
    entity_other: 'Applications',
    search: {
      placeholderLabel: 'Search Applications',
    },
    'row-action': {
      applicationDetailView: 'Application Detail View',
      applicationSetting: 'Application Setting',
      filtersAndScopes: 'Add Filters & Scopes',
      scopeByAccount: 'Scope By Account',
      filterByCriticalResources: 'Filter by Critical Resources',
      scopeByPoc: 'Scope by Point of Contact',
      viewDetailsLabel: 'View Resources',
    },
    empty_message: 'No data to display - Update or clear the filters',
  },
  'lc-list-view-by-resource-table': {
    'column-titles': {
      vulnerabilities: 'Vulnerabilities',
      insights: 'Insights',
    },
    entity_one: 'Resource',
    entity_other: 'Resources',
    search: {
      placeholderLabel: 'Search Resource',
    },
    'row-action': {
      viewDetailsLabel: 'View Context Details',
      downloadSourceLabel: 'Download Source Data',
    },
    empty_message: 'No data to display - Update or clear the filters',
  },
  'lc-list-view-table': {
    fixPriority: {
      critical: 'Critical',
      high: 'High',
      medium: 'Medium',
      low: 'Low',
    },
    'summary-column': {
      'insights-title': 'Insight',
      'threat-detection-title': 'Threat Detection',
      'vulnerability-label': 'Vulnerabilities',
    },
    'column-titles': {
      attackPath: 'Attack Path',
      insights: 'Insights',
      insightsSummary: 'Insights Summary',
      threatFindings: 'Threat Findings',
      threatFindingsSummary: 'Threat Findings Summary',
      risk: 'Risk',
      resources: 'Resources',
      resourceMetadata: 'Resource Metadata',
      resourceName: 'Resource Name',
      applicationName: 'Application',
      resourceType: 'Resource Type',
      cloud: 'Cloud',
      accountName: 'Account Name',
      vulnerabilities: 'Vulnerabilities',
      vulnerabilitiesSummary: 'Vulnerabilities Summary',
      publicAccess: 'Public Access',
      publicAccessTooltip: 'Indicates if the resource has public access exposure. Click "Public" for specific details.',
      criticalities: {
        critical: 'Critical',
        high: 'High',
        medium: 'Medium',
        low: 'Low',
        info: 'Info',
      },
      vulnerabilitySeverity: {
        critical: 'Critical',
        high: 'High',
        medium: 'Medium',
        low: 'Low',
      },
    },
    'risk-layer-tooltips': {
      'attack-path': 'Attack Path Analysis',
      'business-critical': 'Business Critical',
      iam: 'IAM',
      insights: 'Insights',
      'public-access': 'Public Access',
      vulnerability: 'Vulnerabilities',
    },
    tooltips: {
      insights_tooltip:
        'The Insights Summary displays the highest criticality findings applicable to the selected resource (this value will not change based on filtered results).',
      threat_tooltip:
        'The Threat Findings Summary displays the badge (High, Med, Low) for the highest severity threat found for the selected resource. Select the "Resource Name" to view additional details.',
      public_access_tooltip:
        'Indicates if the resource has public access exposure. Click "Public" for specific details.',
      vulnerabilities_tooltip:
        'The Vulnerabilities Summary displays the badge(s) and count (Critical, High, Med, Low) for the highest severity vulnerabilities identified for the selected resource. Hover displays additional details.',
    },
    notExist: 'N/A',
  },
  'lc-resource-pie-chart': {
    entity: 'Resources',
    title: 'High Risk Resource Types',
    pie_hover_tooltip: 'High Risk Resources',
    tooltip:
      'Displays total count of the top 5 occurring resource types in Layered Context with Public Exposure and Critical findings for Insights or Vulnerabilities',
  },
  'lc-clouds-pie-chart': {
    entity: 'Cloud Accounts',
    pie_hover_tooltip: 'High Risk Resources',
    title: 'High Risk Cloud Accounts',
    tooltip:
      'Displays total count of the top 5 occurring cloud accounts in Layered Context with at least 1 Critical Insight Finding and public exposure',
  },
  'lc-applications-pie-chart': {
    entity: 'Applications',
    title: 'High Risk Applications',
    tooltip:
      'Displays total count of the top 5 occurring applications in Layered Context with at least 1 Critical Insight Finding and public exposure',
  },
  'lc-applications-bar-chart': {
    entity: 'Resources',
    title: 'Resource Fix Priority',
    tooltip:
      'Resource Fix Priority counters help the user to get the breakdown of riskiest resources distribution, broken down into critical, high, medium and low severity',
    chart_label: 'Number of Resources',
  },
  'lc-applications-pie-chart-riskiest-type-dist': {
    entity: 'Resources',
    title: 'High Risk Resource Types',
    pie_hover_tooltip: 'High Risk Resources',
    tooltip:
      'Displaying Riskiest Resource Type distribution. Clicking a segment of the pie will filter results accordingly',
  },
  'lc-bar-chart': {
    entity: 'Resources',
    title: 'Insights Summary',
    tooltip:
      'Resource counts based on Insight findings for each severity. A resource can be included in the count for more than one severity if it has findings across multiple Insights with different severity ratings',
    chart_label: 'Number of Resources',
  },
  'lc-resource-key-summary-viz': {
    block_title: 'Risky Resources',
    block_tooltip: 'Total Resources with Public Exposure and Critical findings for Insights or Vulnerabilities',
    high_risk_tooltip:
      'High Risk resources are defined as those resources with Public Exposure and at least 1 Critical Finding for Insights or Vulnerabilities. These resources should be prioritized for review.',
    entity_type: 'High Risk',
    entity: 'Resources',
    percentage_phrase: '(__percentage__%)',
    percentage_phrase_less_than_one: '(less than 1%)',
  },
  'lc-applications-key-summary-viz': {
    block_title: 'Risky Resources',
    block_tooltip: 'Total Resources with Critical Insight findings and Public Exposure',
    high_risk_tooltip:
      'High Risk resources are defined as those resources with Public Exposure and at least 1 Critical Finding for Insights or Vulnerabilities. These resources should be prioritized for review.',
    entity_type: 'High Risk',
    entity: 'Resources',
  },
  'lc-clouds-key-summary-viz': {
    block_title: 'Risky Resources',
    block_tooltip: 'Total Resources with Critical Insight findings and Public Exposure',
    high_risk_tooltip:
      'High Risk resources are defined as those resources with Public Exposure and at least 1 Critical Finding for Insights or Vulnerabilities. These resources should be prioritized for review.',
    entity_type: 'High Risk',
    entity: 'Resources',
  },
  'lc-visualization-collapsible-panel': {
    title: 'Trend and Analytics',
    applications: 'Applications',
    clouds: 'Clouds',
    resources: 'Resources',
  },
};

export default layeredContext;
