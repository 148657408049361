const gcpAdminInstructions = `
Hello,
<br></br>
<br></br>

You are receiving this message because a member of your team needs additional details to onboard a project for GCP (cloud account or accounts) to InsightCloudSec and they do not have the appropriate level of access to gather the information required. 

<br></br>
<br></br>

Refer to the details below for steps on collecting the information required. If you have questions or need assistance, reach out to us through any of the options listed under Getting Support. 

<br></br>
<br></br>

Summary of Configuration Requirements

<br></br>
<br></br>

To enable your user to onboard a project (cloud account) for GCP you will need to complete steps for several configuration items as follows:

<br></br>
<br></br>

<b>- Step 1: Creating a Service Account and Credentials</b>
<br></br>
    <tab>-Expected output: an Email Address for a GCP Service Account </tab>
<br></br>
<b>-Step 2: Creating a Service Account Key</b>
<br></br>
  <tab>-Expected output: a JSON file containing credentials</tab>
<br></br>
  <tab>- <i>Note: We recommend using a secure file sharing system to provide credentials to your non-admin user.</i></tab>
<br></br>
<b>-Step 3: Creating a Role</b>
<br></br>
  <tab>-Expected Output: A new role ID</tab>
<br></br>
<b>-Step 4: Assigning the Role to Your Service</b>
<br></br>
  <tab>-Expected Output: Assigning the role to the service ensures that your non-admin user has the appropriate access to connect your cloud account(s) to InsightCloudSec</tab>
<br></br>
<b>-Step 5: Enabling Email Delegation (Optional but Strongly Recommended)</b>
<br></br>
  <tab>-Expected Output: Email Address of a Domain User for delegation</tab>
<br></br>
  <tab>- <i>Note: While optional, this configuration allows InsightCloudSec to harvest additional data at the domain level; specifically harvesting and having visibility into Domain Users and Domain Groups (these require email delegation)</i></tab>

<br></br>
<br></br>
<br></br>

<b>External References</b>
- <supportLink>Recommended/Supported APIs</supportLink>

<br></br>
<br></br>

<b>Step 1: Creating a Service Account</b>

<br></br>
<br></br>

<i>Service accounts can only be created within a Project, so choose the Project, or consider creating a new Project for the service account for InsightCloudSec to reside in. The following steps will take place within that Project. 
<br></br>
<br></br>
Note: If you are onboarding a single Project we recommend that you create the new service account within that Project. </i>
<br></br>
<br></br>

<ol>
  <li>Navigate into "IAM & Admin > Service Account".</li>
  <li>Click "Create Service Account" and complete the service account details.</li>
  <li>We recommend including ICS or InsightCloudSec here for tracking purposes.</li>
  <li>Click "Done" to create the Service Account.</li>
<li>The Service Account email from the "Details" tab is used for later steps, so save this information to a safe place or leave this page open and continue in another tab.</li>
</ol>

<br></br>
<br></br>

<b>Step 2: Creating a Service Account Key</b>
<br></br>
<br></br>

<ol>
  <li>Navigate into the newly created Service Account.</li>
  <li>In the KEYS section, select "ADD KEY".</li>
  <li>Select "Create New Key".</li>
  <li>With Key Type as JSON, click "Create" to download the key.</li>
  <li>Store this JSON file in a secure place; it contains the only copy of the key.</li>
</ol>

<br></br>
<br></br>

<b>Step 3 Creating a Role</b>
<br></br>
<br></br>
NOTE: Organization Onboarding vs. Single Account Onboarding
<br></br>
<br></br>
For onboarding a GCP Organization, the following steps take place at the Organization level. Navigate into the Organization using the project selection dropdown and select the appropriate Domain. 
<br></br>
<br></br>

For onboarding a single GCP Project, if you are not in the Project from earlier steps, select the Project you wish to onboard.
<br></br>
<br></br>

<ol>
<li>Navigate into "IAM & Admin > Roles".</li>
<li>Click "Create Role".</li>
<li>Name your role and give it a description.</li>
    <tab>- We recommend including ICS or InsightCloudSec here for tracking purposes.
    <br></br>
<li>Click add permissions, and using the filter field provided, select the following permissions:</li>
  <br></br>
    <tab>- <code>bigquery.tables.get</code></tab>
    <br></br>
    <tab>- <code>bigquery.tables.list</code></tab>
    <br></br>
    <tab>- <code>cloudasset.assets.listResource</code></tab>
    <br></br>
    <tab>- <code>cloudasset.assets.searchAllIamPolicies</code></tab>
    <br></br>
    <tab>- <code>serviceusage.services.enable</code></tab>
    <br></br>
    <tab>- <code>storage.buckets.get</code></tab>
    <br></br>
    <tab>- <code>storage.buckets.getIamPolicy</code></tab>
  <br></br>
  <br></br>
    <tab><i><b>Additional configuration note:</b> If you are interested in using our <guardRailLink>Kubernetes Security Guardrails</guardRailLink> and <remoteScannerLink>Kubernetes Remote Scanner</remoteScannerLink>, we recommend including the required permission(s) (<code>container.secrets.list</code>) for those features at this step. Details on <gkeLink>GCP (GKE) support are available here.</gkeLink></i></tab>
  <br></br>
  <li>Click "Add" to finalize the permissions.</li>
  <li>Click "Create" to save the role.</li>
</ol>

<br></br>
<br></br>
<br></br>

<b>Step 4: Assigning Your Policy to Your User</b>

<br></br>
<br></br>

<ol>
  <li><b>For a single Project:</b></li> 
    <tab>- Navigate to "IAM & Admin--> IAM" and click "Grant Permissions".</tab>
    <br></br>
    <br></br>
    <tab><b>For an Organization:</b></tab>
    <br></br>
    <tab>- Navigate into the Organization using the Project selection dropdown and select the appropriate Domain in the listing. Then Navigate to "IAM & Admin--> IAM" and click "Grant Permissions".</tab>
    <br></br>
    <br></br>
    <br></br>
  <li>Paste in the Service Account Email (taken from the Service Account details page) into the "New principals" field.</li>
  <li>Add the Roles listed below to this Service Account. All 5 of the roles listed here are required to properly harvest resource data in InsightCloudSec for a GCP Organization*. Note: Only the Basic Viewer and Custom InsightCloudSec role are required for onboarding single Projects. </li>
    <tab>- Resource Manager -> Organization Viewer (Organization Administrator if you're setting up write permissions)</tab>
    <br></br>
    <tab>- Resource Manager -> Folder Viewer</tab>
    <br></br>
    <tab>- IAM -> Security Reviewer</tab>
    <br></br>
    <tab>- Basic -> Viewer (Editor to allow InsightCloudSec to have write permissions into GCP)*</tab>
    <br></br>
    <tab>- Custom -> Custom InsightCloudSec Role created in previous steps*</tab>
  <li>Click "Save".</li>
</ol>

<br></br>
<br></br>

<b>Step 5: Enabling Email Delegation (Optional but recommended)</b>

<br></br>
<br></br>

We strongly encourage you to enable email delegation. This configuration allows InsightCloudSec to harvest additional data at the domain level; specifically harvesting and visibility into Domain Users and Domain Groups.

<br></br>
<br></br>
<br></br>

<u>Enabling Delegation - Part 1</u>
<br></br>
<br></br>

<ol>
  <li>Navigate to "IAM & Admin--> Service Accounts”.</li>
  <li>Open to view the details of your InsightCloudSec configured service account. Scroll to advanced settings and locate "Domain-wide Delegation".</li>
  <li>Click to copy the "Client ID" for your Service account.</li>
  <li>Under Client ID, click on the "View Google Workspace Admin Console" and move to the next section of steps.</li>
</ol>
<br></br>
<br></br>

<u>Enabling Delegation - Part 2</u>
<br></br>
<br></br>

<ol>
  <li>Navigate to "Security → Overview ”.</li>
  <li>Scroll to "API Controls" and click to expand.</li>
  <li>Under API Controls scroll to locate "Domain-wide Delegation" and click "Manage Domain Wide Delegation".</li>
  <li>In the following step you will either need to: </li>
      <tab>- Search for and confirm that the Client ID you copied from your service account already exists, or:</tab>
    <br></br>
      <tab>- Click "Add new" and add the Client ID to specify the service account you want to configure for Domain-wide delegation.</tab>
</ol>

<br></br>
<br></br>

 Note: For an existing client ID (a.) verify the following scopes. For a new Client ID (b.) these scopes will have to be added:
 <br></br>

 <ul>
  <li>https://www.googleapis.com/auth/admin.directory.user</li>
  <li>https://www.googleapis.com/auth/admin.directory.group</li>
  <li>https://www.googleapis.com/auth/admin.directory.group.member</li>
 </ul>

 <br></br>
 <br></br>
 <br></br>

<b>Enabling Delegation - Part 3 </b>
<br></br>
<ol>
  <li>Navigate to "Directory → Users”.</li>
  <li>Filter for Admin Role and select "Super Admin" to narrow the list of user accounts.</li>
  <li>Identify the email for the account you want to use to specify for Domain-wide Delegation.</li>
    <tab>- Save this email in a safe place to share with your non-admin user. </tab>
</ol>

<br></br>
<br></br>
<br></br>

Thank you for completing the required administrative setup steps! Please provide the <b>email delegation address</b> and <b>Credentials JSON file</b> to your non-admin user so they can complete the onboarding process. 

<br></br>
<br></br>


`;

export default gcpAdminInstructions;
