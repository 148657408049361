const resourcePanel = {
  'risk-tab': {
    title: 'Risk',
    summary: 'Summary',
    'summary-item': {
      yes: 'Yes',
      no: 'No',
      'view-proof': 'View Proof',
    },
    'summary-items': {
      'business-critical': {
        title: 'Is Business Critical',
        missing: 'This resource does not belong to a critical application',
        present: 'Business Critical Application',
      },
      'public-access': {
        title: 'Is Public Access',
        missing:
          'There are no public access insights or other possible sources indicating public access for this resource.',
        present: 'There are <a1>public access insights</a1> indicating public access for this resource',
      },
      'attack-path': {
        title: 'Is On Known Attack Path',
        missing: 'This resource is not found on a known attack path',
        present: 'Found Attack Paths',
      },
      iam: {
        title: 'Contains Severe IAM Misconfigurations',
        missing: 'There are no critical or high severity IAM misconfigurations found on this resource.',
        present: 'Crtical/High Insights',
      },
      'threat-findings': {
        title: 'Contains Severe Threat Findings',
        missing: 'There are no high threat findings found on this resource.',
        present: 'High Threat Findings',
      },
      vulnerabilities: {
        title: 'Contains Severe Vulnerabilities',
        missing: 'There are no critical or high severity vulnerabilities found on this resource.',
        present: {
          critical: 'Critical Findings',
          high: 'High Findings',
        },
      },
    },
  },
};

export default resourcePanel;
