const alibabaAdminInstructions = `
Hello,

<br></br>
<br></br>

You are receiving this message because a member of your team needs additional details to onboard a cloud account (or accounts) to InsightCloudSec and they do not have the appropriate level of access to gather the information required. 

<br></br>
<br></br>

Refer to the details below for steps on collecting the information required. If you have questions or need assistance, reach out to us through any of the options listed under <supportLink>Getting Support.</supportLink> 

<br></br>
<br></br>
<br></br>
<b><i>Summary of Configuration Requirements</i></b>

<br></br>
<br></br>

To enable your user to onboard a cloud account for for Alibaba Cloud you will need to complete steps for three configuration items as follows:

<br></br>
<br></br>

- <b>Step 1: Creating a Custom RAM Policy</b>
<br></br>
    <tab>- Expected output: A Custom RAM policy for access to InsightCloudSec</tab>
    <br></br>
- <b>Step 2: Creating a User</b>
<br></br>
    <tab>- Expected Output: A new user, access key ID, access key secret</tab>
    <br></br>
    <tab>- <i>Note: We recommend using a secure file sharing system to provide credentials to your non-admin user.</i></tab>
    <br></br>
- <b>Step 3: Assigning the Policy to Your User</b>

<br></br>

  <tab>- Expected Output: This action is required to ensure that the credentials you share with the non-admin user will include the appropriate access and enable them to connect your Alibaba Cloud account(s) with InsightCloudSec successfully. </tab>

<br></br>
<br></br>

<b>External References</b>

<br></br>

- <policyLink>Policy details are available here.</policyLink>
<br></br>
- <productDocLink>Product Documentation is available here.</productDocLink>

<br></br>
<br></br>
<br></br>

<b>Step 1: Create a Custom RAM Policy</b>

<ol>
 <li>From the Alibaba Console, navigate to the "Products and Services” list.</li>
 <li>Under “Operations and Maintenance", select "Resource Access Management".</li>
 <li>To create the required RAM Policy, select "Policies" under "Permissions" on the RAM navigation menu.</li>
 <li>Click “Create Policy”, and then click “JSON”.</li>
 <li>In the “JSON” section, paste the details from our Read Only Resource Access Management (RAM) JSON policy <s3Link>from our public S3 bucket.</s3Link></li>
  <tab>-The Read-Only policy contains only read permissions for the Alibaba Cloud resources that InsightCloudSec supports. </tab>
    <br></br>
    <tab>-Note: This policy will need to be updated any time InsightCloudSec supports a new Alibaba Cloud service.</tab>
  <br></br>

  <li>Click “Next to edit policy information”.</li>
  <li>Give the policy a name.	</li>
  <ul>
    <li>Note special character rules (for example: spaces are not allowed)</li>
    <li>Add a note/description if desired.</li>
  </ul>
  <li>Click “OK” to finalize your RAM policy and then verify that your policy was successfully created under the Policies section. </li>
</ol>

<br></br>
<br></br>

<b>Step 2: Create a User</b>

<br></br>

<ol>
  <li>From the RAM navigation menu, select "Users" under the "Identity" section</li>
  <li>Click "Create User".</li>
  <li>Provide a logon name.  </li>

    <tab>- Note special character rules (for example: spaces are not allowed)</tab>
    <br></br>
    <tab>- Add a display name if desired.</tab>
  <li>Under Access Mode, select "OpenAPI Access".</li>
  <li>Select "OK" to finalize the creation of the user. </li>
</ol>

<br></br>

THIS WILL OPEN A NEW USER INFO SCREEN

<br></br>
<br></br>

Save the Access Key information, both the Access Key ID and Access Key Secret, in a safe location to share with your non-admin user. 

<br></br>
<br></br>

<b>Step 3: Assign Your Policy to Your User</b>

<br></br>
<br></br>

<ol>
  <li>Locate the user you created and click on the name. </li>
  <li>Select the "Permissions" tab under the Basic Information, and then under the “Individual” tab, select "Grant Permission".</li>
  <li>Select "Custom Policy" as the type of policy to add.</li>
  <li>Search for and select the name of the policy you created, and then Click "OK" to finalize the changes.</li>
  <li>Confirm permissions have been authorized and click “Complete”.</li>
  <li>View the User details to confirm that the policy (permissions) have been added for this user.</li>
</ol>

<br></br>
<br></br>

Thank you for completing the required administrative setup steps! Please provide the <b>Access Key ID</b> and <b>Access Key Secret</b> to your non-admin user so they can complete the InsightCloudSec cloud onboarding process. 
`;

export default alibabaAdminInstructions;
