export const pages = {
  'vulnerability-assessment': {
    title: 'Container Vulnerability Management',
    components: {
      filter: 'Filter',
      apply: 'Apply',
    },
  },
  login: {
    form: {
      username: {
        label: 'Username',
        error: 'Username is required.',
      },
      password: {
        label: 'Password',
        error: 'Password is required.',
      },
      mfa: {
        label: 'MFA Token',
        error: 'MFA Token is required.',
      },
    },
    'forgot-password': {
      label: 'Forgot your password?',
      modal: {
        title: 'Forgot Password',
        msg: 'If an email address is associated with your username an email will be sent with a password reset link.',
      },
      'success-msg':
        'If an email address was found for "__name__" then a message will be sent. Follow link in email to reset password.',
    },
    'login-button': 'Login',
    'browser-compatibility':
      'For the best performance, we recommend using the latest version of Chrome on a non-mobile device.',
    redirect: {
      msg: 'Redirecting shortly...',
    },
    mfa: {
      'info-alert':
        'Your administrator has required you setup MFA. Do not navigate away, you will only see this token once.',
      token: {
        label: 'OTP Secret Token',
      },
      authenticator: {
        recommend: 'We recommend Google Authenticator',
        download: 'Download',
        'google-authenticator': 'Google Authenticator',
        iOS: 'iOS',
        android: 'Android',
        'continue-btn': 'Continue',
        confirm: { msg: "Are you sure you've stored the Secret Token in an authenticator?", yes: 'Yes', no: 'No' },
      },
      'back-to-login': 'Back to Login',
    },
    sso: {
      label: 'Using Single Sign-On?',
      'unrecognized-sso-username': 'Single Sign-On Username "__name__" unrecognized.',
      form: {
        title: 'Sign in with your organization',
        email: {
          label: 'SSO Username',
          error: 'SSO Username is required.',
        },
      },
    },
  },
};
