export const clouds = {
  edh: {
    table: {
      columns: {
        account: 'Account Configured',
        events: 'Events',
        regions: 'Regions',
        'enabled-resources': 'Enabled Resources',
        status: 'Status',
        consumer: 'Target Consumer',
      },
      status: {
        pending: 'pending',
        error: 'error',
        enabled: 'enabled',
      },
      header: {
        entity_one: 'EDH Producer Configuration',
        entity_other: 'EDH Producer Configurations',
      },
      actions: {
        add: {
          label: 'Add Producer',
        },
        delete: {
          label: 'Delete',
          statuses: {
            mutating: 'Deleting EDH Producer Configurations...',
            success: 'EDH Producer Configuration(s) successfully removed.',
            error: 'Could not delete EDH Producer Configuration(s)',
          },
        },
        update: 'Update',
        refresh: {
          label: 'Refresh',
          prompt: {
            question: 'Are you sure you want to resfresh this producer?',
          },
          error: 'Something went wrong while refreshing.',
          success: 'Successfully triggered a refresh. ',
        },
      },
      filters: {
        badges: {
          label: 'Badges',
          placeholder: 'Select Badge(s)...',
        },
        consumers: {
          label: 'Consumers',
          placeholder: 'Select Consumer...',
        },
        clouds: { label: 'Clouds', placeholder: 'Select Cloud Provider' },
        clear: 'Clear All',
        apply: 'Apply',
      },
      search: 'Search EDH Producers',
    },
    'error-modal': {
      columns: {
        action: 'Action',
        message: 'Message',
        region: 'Region',
        time: 'Time',
      },
    },
    'configuration-modal': {
      'create-title': 'Create Auto-Provisioning Producer',
      'edit-title': 'Update Auto-Provisioning Producer - __name__',
      rules:
        'When enabled, several EventBridge Rules will be created in each AWS region to match CloudTrail events for the selected Resource Types. Each EventBridge Rule will have a EventBridge Target configuration to send the events to the EventBus in the designated Consumer AWS Account.',
      availability:
        'Note this feature is only available for AWS accounts currently configured as Auto-provisioning EventBridge consumers.',
      'submit-statuses': {
        creating: 'Creating EDH Producer Configuration',
        updating: 'Updating EDH Producer Configuration',
        success: 'Successfully created EDH Producer Configuration',
        updated: 'Successfully updated EDH Producer Configuration',
        error: 'Something went wrong during EDH Producer Configuration. __message__',
      },
      form: {
        sections: {
          'consumer-producer': 'Select Consumer and Producer',
          'consumer-producer-subtext':
            'Select the target consumer account to receive change events and the producer account that will publish events to the selected consumer.',
          'resource-types': 'Select Resource Types',
          options: 'Options',
        },
        labels: {
          consumer: 'Consumer',
          consumers: 'Consumers',
          producer: 'Producer',
          'resource-types': 'Resource Types',
          'enable-all-types': 'Automatically update enabled resource types to use all resource types',
          badges: 'Badges',
          'copy-per-badges': 'Copy configuration to clouds with badges',
          'copy-to-coproducers': 'Copy configuration to the co-producers of a consumer',
          iam: 'Automatically provision IAM resources',
          cloudwatch: 'Automatically provision Cloudwatch resources',
        },
        validations: {
          'message-single-select': 'A __entity__ must be selected.',
          'message-multiselect': 'A minimum of one __entity__ must be selected.',
        },
      },
    },
    'simple-error-modal': {
      'event-collection-halted-by-an-error': `Event collection halted by an error. To resume collection, the Edit form must be resubmitted.
      Submitting the form without any changes will simply retry collection. Click Reconfigure below, to open
      the Edit form.`,
    },
    'consumer-table': {
      stopped: 'Stopped',
      collecting: 'Collecting',
      pending: 'Pending',
      'stopped-with-error': 'Stopped with error',
      provisioning: 'Provisioning',
      provisioned: 'Provisioned',
      deprovisioning: 'Deprovisioning',
      deleting: 'Deleting',
      'deprovisioning-complete': 'Deprovisioning complete. Awaiting consumer configuration cleanup.',
      error: 'Error',
      'successfully-deleted-configuration': 'Successfully deleted configuration',
      'successfully-saved-configuration': 'Successfully saved __instance__ Configuration',
      'successfully-triggered-a-refresh': 'Successfully triggered a refresh',
      'account-name': 'Account Name',
      region: 'Region',
      status: 'Status',
      'auto-provision': 'Auto-provision',
      'queue-name': 'Queue Name',
      'data-source': 'Data Source',
      eventbridge: 'EventBridge',
      eventgrid: 'EventGrid',
      cloudtrail: 'CloudTrail',
      'cloud-asset-inventory': 'Cloud Asset Inventory',
      'n/a': 'n/a',
      'this-immediately-deletes-the-configuration-and-stops-event-collection': `This immediately deletes the configuration and stops event collection from '__namespace_id__' for the __account_name__ (__account_id__) account.
        <br>
        <br>
        This action does not delete or modify the cloud resources.
        <br>
        To resume collecting events, simply create a new configuration with the same values.
        `,
      'step-1-configure-consumers': 'Step 1: Configure Consumers',
      'consumers-are-required-to-aggregate':
        'Consumers are required to aggregate change events across one or more of your cloud accounts.',
      'aws-sqs-consumer': 'AWS SQS Consumer',
      'gcp-pubsub-consumer': 'GCP PubSub Consumer',
      'azure-servicebus-consumer': 'Azure ServiceBus Consumer',
      'aws-sqs-with-auto-provisioning': 'AWS SQS with Auto Provisioning',
      edit: 'Edit',
      delete: 'Delete',
      'edit-all-for-account': 'Edit all for Account',
      'delete-all-for-account': 'Delete all for Account',
      'delete-all-configurations': 'Delete all configurations for this Account',
      'this-immediately-marks-all-configurations': `This immediately marks all configrations for __account_name__ (__account_id__) in a delete
       state and stops events collection. A background process will clean up all of the auto provisioned
       EventBridge Rules and Targets. Once complete, the configurations will be completely deleted and removed from
       this view. Note: this is only applicable for AWS EventBridge consumer configurations that are setup at the account level
       via the "AWS SQS with Auto Provisioning" form.`,
      'successfully-disabled-event-driven-harvesting': 'Successfully disabled event-driven-harvesting for this account',
      'auto-provision-scan': 'Auto Provision Scan',
      'enqueues-a-background-job-to-scan-auto-provisioned': `
      Enqueues a background job to scan auto provisioned resources and to apply any necessary changes.
      <br>
      This job is run periodically and when modifications are made.
      <br>
      <br>
      This action should be rarely used and only in situations where auto provisioned resources have been directly modified or when directed by a support.`,
      'this-job-is-run-periodically': 'This job is run periodically and when modifications are made.',
      'this-action-should-be-rarely-used':
        'This action should be rarely used and only in situations where auto provisioned resources have been directly modified or when directed by a support.',
      'event-receiving-has-stopped-without-any-errors':
        'Event receiving has stopped without any errors. This is an unknown error state. Please edit and save the consumer config without any changes to retry.',
      'event-receiving-has-stopped':
        'Event receiving has stopped as resources are being auto provisioned or validated.',
      'event-receiving-has-stopped-with-an-error': 'Event receiving has stopped with an error.',
      'collection-stopped': 'Collection stopped. Awaiting deprovisioning.',
      'deployed-resources-are-being-removed': 'Deployed resources are being removed',
      'add-edh-configuration': 'Add EDH Configuration',
    },
    'events-summary': {
      visualizations: {
        processedEventOverview: {
          title: 'Processed Event Overview',
          description: 'Total events processed since EDH was enabled',
          descriptionSince: 'Total events processed since __date__',
        },
        userAgentBreakdown: 'User Agent Breakdown',
        eventCount: 'Event Count',
        suspiciousEventCount: 'Suspicious Event Count',
        topEventsByType: 'Top Events by type ',
        topEventProducingClouds: 'Top Event Producing Clouds',
      },
      filters: {
        label: 'Cloud',
        placeHolder: 'Select cloud',
      },
    },
  },
};

export default clouds;
