export const enums = {
  HostAssessmentErrorReason: {
    defaultKmsKey:
      "You can't share snapshots that are encrypted with the default AWS managed key. You can only share snapshots that are encrypted with a customer managed key.",
    defaultKmsKeyRegion:
      "You can't share snapshots that are encrypted with the default AWS managed key in this region. You can only share snapshots that are encrypted with a customer managed key.",
    exportPermission: 'Snapshot export permissions failure',
    kmsGrant: 'Grant could not be created for KMS key.',
    kmsGrantPermission: 'User is not authorized to create a grant for the KMS key.',
    missingCsek: "You can't share snapshots that are encrypted with a Customer Supplied Encryption Key.",
    organizationRemoved: 'The organization service for this resource has been removed.',
    snapshotPermissions: 'Snapshot permissions failure',
    unknown: 'Unknown',
    volumeTooLarge: 'The root volume is too large for assessment.',
    r7KmsSnapshotPermissions: 'User is not authorized to copy snapshots using the R7 CMK.',
    copySnapshot: 'Failure to copy default key encrypted snapshot',
  },
  exploitSource: {
    exploitdb: 'ExploitDB',
    metasploit: 'Metasploit',
  },
  threatSource: {
    fbi: 'FBI',
    infoSharing: 'InfoSharing',
    infoSharingGreen: 'InfoSharing/Green',
    infoSharingWhite: 'InfoSharing/White',
    openSourceWhite: 'OpenSource/White',
    openSource: 'OpenSource/',
    heisenbergWhite: 'Heisenberg/White',
    cisa: 'CISA',
    r7: 'Rapid7 Research',
  },
  severity: {
    info: 'Info',
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    critical: 'Critical',
  },
};
